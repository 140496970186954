<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="640"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 8 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="12" :xs="24">
          <!-- 充电柜编码 -->
          <a-form-item label="充电柜编码:">
            <div class="ud-text-secondary">
              {{ data.cabinetCode }}
            </div>
          </a-form-item>
          <!-- 所属运营商 -->
          <a-form-item label="所属运营商:">
            <div class="ud-text-secondary">
              {{ data.operatorName }}
            </div>
          </a-form-item>
          <!-- 所属租赁点 -->
          <a-form-item label="所属租赁点:">
            <div class="ud-text-secondary">
              {{ data.pointName }}
            </div>
          </a-form-item>
          <!-- 换电柜协议 -->
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 厂家 -->
          <a-form-item label="换电柜名称:">
            <div class="ud-text-secondary">
              {{ data.cabinetName }}
            </div>
          </a-form-item>
          <!-- 型号 -->
          <a-form-item label="型号:">
            <div class="ud-text-secondary">
              {{ data.model }}
            </div>
          </a-form-item>
          <a-form-item label="换电柜协议:">
            <div class="ud-text-secondary">
              {{ data.protocolType }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'cabinetDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  methods: {
    // typeProtocol(protocolType) {
    //   if (protocolType === 45) {
    //     return '协议one'
    //   }
    //   if (protocolType === 46) {
    //     return '协议two'
    //   }
    // },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
</style>
